import Vue from 'vue';
export const EventBus = new Vue();
window.vueEvent = EventBus;

// define action name here
export const EXAMPLE_ACTION = 'ExampleAction';

export const DROPS_LEADERBOARD_FILTER = 'DROPS_LEADERBOARD_FILTER';

export const DROPS_SHOW_WINNER = 'DROP_SHOW_WINNER';
export const DROPS_UPDATE_WHOLE_BAGS = 'DROP_UPDATE_WHOLE_BAGS';
export const DROPS_USER_WATCH_CODERED = 'DROPS_USER_WATCH_CODERED';

export const NOTIFICATION_USER_REWARD_GROUP = 'NOTIFICATION_USER_REWARD_GROUP';
export const NOTIFICATION_USER_REWARD = 'NOTIFICATION_USER_REWARD';
export const NOTIFICATION_VERIFY_GAME_NAME = 'NOTIFICATION_VERIFY_GAME_NAME';
export const NOTIFICATION_CONNECT_3P_ACCOUNT = 'NOTIFICATION_CONNECT_3P_ACCOUNT';
export const NOTIFICATION_USER_FUNDS_ADDED = 'NOTIFICATION_USER_FUNDS_ADDED';
export const NOTIFICATION_USER_FUNDS_CLEAR_PROCESSING_PAYMENT = 'NOTIFICATION_USER_FUNDS_CLEAR_PROCESSING_PAYMENT';

export const DROPS_SMASH_CAKE = 'DROPS_SMASH_CAKE';

export const INTEL_INSPIRES_FINISH_STEP_PLAYER = 'INTEL_INSPIRES_FINISH_STEP_PLAYER';
export const INTEL_INSPIRES_FINISH_STEP_RECRUITER = 'INTEL_INSPIRES_FINISH_STEP_RECRUITER';
export const INTEL_INSPIRES_EDIT_RECRUITER = 'INTEL_INSPIRES_EDIT_RECRUITER';
export const INTEL_INSPIRES_EDIT_PLAYER = 'INTEL_INSPIRES_EDIT_PLAYER';
export const PROFILE_CHANGE_TAB = 'PROFILE_CHANGE_TAB';
export const PROFILE_CLICK_CHANGE_TAB = 'PROFILE_CLICK_CHANGE_TAB';
export const PROFILE_SAVE_ALL_SECTION = 'PROFILE_SAVE_ALL_SECTION';
export const PROFILE_UPDATE_PINNED_SECTION = 'PROFILE_UPDATE_PINNED_SECTION';
export const PROFILE_UPDATE_UNPINNED_SECTION = 'PROFILE_UPDATE_UNPINNED_SECTION';

export const SHOW_VIDEO_CLIP_EMBED = 'SHOW_VIDEO_CLIP_EMBED';
export const VIDEO_CLIP_EMBED_HIDE = 'VIDEO_CLIP_EMBED_HIDE';
export const SHOW_MODAL_LOGIN = 'SHOW_MODAL_LOGIN';
export const SHOW_MODAL_LOGIN_TWITCH = 'SHOW_MODAL_LOGIN_TWITCH';
export const SHOW_MODAL_ONBOARDING_INITIAL = 'SHOW_MODAL_ONBOARDING_INITIAL';
export const SHOW_MODAL_ONBOARDING_VERIFY = 'SHOW_MODAL_ONBOARDING_VERIFY';
export const SHOW_MODAL_ONBOARDING_GET_STARTED = 'SHOW_MODAL_ONBOARDING_GET_STARTED';
export const SHOW_MODAL_ONBOARDING_NONE_STUDENT = 'SHOW_MODAL_ONBOARDING_NONE_STUDENT';
export const SHOW_MODAL_ONBOARDING_STUDENT = 'SHOW_MODAL_ONBOARDING_STUDENT';
export const SHOW_MODAL_ONBOARDING_HUB = 'SHOW_MODAL_ONBOARDING_HUB';
export const SHOW_MODAL_ONBOARDING_CHOOSE_ROLE = 'SHOW_MODAL_ONBOARDING_CHOOSE_ROLE';
export const SHOW_MODAL_SMASH_CAKE = 'SHOW_MODAL_SMASH_CAKE';
export const SHOW_MODAL_RECEIVE_REWARD = 'SHOW_MODAL_RECEIVE_REWARD';
export const SHOW_MODAL_BOOM_MESSAGE = 'SHOW_MODAL_BOOM_MESSAGE';
export const SHOW_MODAL_BOOM_CONFIRM = 'SHOW_MODAL_BOOM_CONFIRM';
export const SHOW_MODAL_UPDATE_EMAIL_TWITTER = 'SHOW_MODAL_UPDATE_EMAIL_TWITTER';
export const SHOW_MODAL_CONNECT_ACCOUNTS = 'SHOW_MODAL_CONNECT_ACCOUNTS';
export const SHOW_MODAL_CONNECT_SOCIAL_PLATFORM = 'SHOW_MODAL_CONNECT_SOCIAL_PLATFORM';
export const SHOW_MODAL_AUTO_ADD_MEMBER_TO_HUB = 'SHOW_MODAL_AUTO_ADD_MEMBER_TO_HUB';
export const SHOW_MODAL_SELECT_MANAGE_HUB = 'SHOW_MODAL_SELECT_MANAGE_HUB';
export const SHOW_MODAL_RESET_PASSWORD = 'SHOW_MODAL_RESET_PASSWORD';
export const SHOW_MODAL_QUEUEUP_SURVEY = 'SHOW_MODAL_QBUDDY_SURVEY';
export const SHOW_MODAL_FUTURE_CREATOR = 'SHOW_MODAL_FUTURE_CREATOR';
export const SHOW_MODAL_QUALIFIER_MESSAGE = 'SHOW_MODAL_QUALIFIER_MESSAGE';
export const SHOW_MODAL_CREATE_DISCORD_CHANNEL = 'SHOW_MODAL_CREATE_DISCORD_CHANNEL';
export const SHOW_MODAL_CONFIRM_ADMIN_HIGHSCHOOL = 'SHOW_MODAL_CONFIRM_ADMIN_HIGHSCHOOL';
export const SHOW_MODAL_SWITCH_APP = 'SHOW_MODAL_SWITCH_APP';

export const LOAD_MORE_NOTIFICATION = 'LOAD_MORE_NOTIFICATION';

export const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS';
export const CHECK_AND_SHOW_HUB_ONBOARDING = 'CHECK_AND_SHOW_HUB_ONBOARDING';
export const GET_DATA_BEFORE_CONNECT_SOCIAL = 'GET_DATA_BEFORE_CONNECT_SOCIAL';

export const STREAMER_PARTNERSHIP_DATA = 'STREAMER_PARTNERSHIP_DATA';
export const GAME_ACCOUNT_CONNECTION_TOGGLE_MODAL = 'GAME_ACCOUNT_CONNECTION_TOGGLE_MODAL';
export const PROFILE_CONNECT_GAME_NAME_SUCCESS = 'PROFILE_CONNECT_GAME_NAME_SUCCESS';
export const PROFILE_REMOVE_GAME_NAME_SUCCESS = 'PROFILE_REMOVE_GAME_NAME_SUCCESS';
export const PROFILE_UPDATE_SETTING = 'PROFILE_UPDATE_SETTING';
export const UPDATE_CONNECTED_GAMES = 'UPDATE_CONNECTED_GAMES';
export const PROFILE_UPDATE_AVATAR = 'PROFILE_UPDATE_AVATAR';
export const HANDLE_CHANGE_THEME = 'HANDLE_CHANGE_THEME';
export const HANDLE_CHANGE_THEME_SETTING = 'HANDLE_CHANGE_THEME_SETTING';

export const INTEL_INSPIRES_RECRUIT_PLAYER_FILTER = 'INTEL_INSPIRES_RECRUIT_PLAYER_FILTER';
export const INTEL_INSPIRES_RECRUIT_SEARCH_FILTER = 'INTEL_INSPIRES_RECRUIT_SEARCH_FILTER';
export const INTEL_INSPIRES_RECRUIT_GAME_FILTER = 'INTEL_INSPIRES_RECRUIT_GAME_FILTER';
export const INTEL_INSPIRES_GAME_DATA_LOADING = 'INTEL_INSPIRES_GAME_DATA_LOADING';
export const INTEL_INSPIRES_GAME_DATA = 'INTEL_INSPIRES_GAME_DATA';
export const OPEN_UPCOMING_INTEL_INSPIRES_EVENT = 'OPEN_UPCOMING_INTEL_INSPIRES_EVENT';
export const INTEL_INSPIRES_ADD_PLAYER_TO_UPCOMMING_EVENT = 'INTEL_INSPIRES_ADD_PLAYER_TO_UPCOMMING_EVENT';

export const SHOW_CHAT = 'SHOW_CHAT';
export const CHAT_WITH_COACH = 'CHAT_WITH_COACH';
export const ORDER_WITH_COACH = 'ORDER_WITH_COACH';
export const OPEN_REPORT = 'OPEN_REPORT';
export const OPEN_MATCH_REPORT_FROM_CHAT = 'OPEN_MATCH_REPORT_FROM_CHAT';
export const OPEN_REPORT_SCORE_FROM_CHAT = 'OPEN_REPORT_SCORE_FROM_CHAT';

export const TOURNAMENT_TOGGLE_CHAT = 'TOURNAMENT_TOGGLE_CHAT';

export const USER_WALLET_FUNDS_ADDED = 'USER_WALLET_FUNDS_ADDED';
export const USER_WALLET_FUNDS_WITHDRAWN = 'USER_WALLET_FUNDS_WITHDRAWN';

export const USER_SUBMIT_PHONE_NUMBER = 'USER_SUBMIT_PHONE_NUMBER';

export const CHANGE_START_TIME_ORDER_PARTNER = 'CHANGE_START_TIME_ORDER_PARTNER';

/** event for lobby session */
export const LOBBY_SESSION_EVENT_SESSION_STARTED = 'LOBBY_SESSION_EVENT_SESSION_STARTED';
export const LOBBY_SESSION_EVENT_SESSION_COMPLETED = 'LOBBY_SESSION_EVENT_SESSION_COMPLETED';
export const LOBBY_SESSION_EVENT_SESSION_CANCELED = 'LOBBY_SESSION_EVENT_SESSION_CANCELED';
export const LOBBY_SESSION_EVENT_GAME_STARTED = 'LOBBY_SESSION_EVENT_GAME_STARTED';
export const LOBBY_SESSION_EVENT_GAME_COMPLETED = 'LOBBY_SESSION_EVENT_GAME_COMPLETED';
export const LOBBY_SESSION_EVENT_GAME_CANCELED = 'LOBBY_SESSION_EVENT_GAME_CANCELED';
export const LOBBY_SESSION_EVENT_GAME_RESTARTED = 'LOBBY_SESSION_EVENT_GAME_RESTARTED';
export const LOBBY_SESSION_FORCE_START_GAME = 'LOBBY_SESSION_FORCE_START_GAME';
/** end event for lobby session */

export const UPDATE_LIST_EVENT_CALENDAR = 'UPDATE_LIST_EVENT_CALENDAR';
export const CLOSE_EVENT_CALENDAR = 'CLOSE_EVENT_CALENDAR';
export const FILTER_CALANDAR_BY_GAME = 'FILTER_CALANDAR_BY_GAME';
export const EVENT_CALENDAR_FORCE_SHOW = 'EVENT_CALENDAR_FORCE_SHOW';
export const TOGGLE_TOP_MENU = 'TOGGLE_TOP_MENU';
export const TOGGLE_TOP_MENU_LOGO = 'TOGGLE_TOP_MENU_LOGO';
export const CLICK_TOP_MENU_HAMBURGER = 'CLICK_TOP_MENU_HAMBURGER';
export const STOP_FORCE_SHOW_MENU_HAMBURGER = 'STOP_FORCE_SHOW_MENU_HAMBURGER';
export const TOGGLE_DROPDOWN_PROFILE_TOP_HEADER = 'TOGGLE_DROPDOWN_PROFILE_TOP_HEADER';
export const SIGN_UP_VERIFY_GAME_NAME = 'SIGN_UP_VERIFY_GAME_NAME';
export const CALENDAR_MARQUEES_SHOW_GRID = 'CALENDAR_MARQUEES_SHOW_GRID';

export const SHOW_MODAL_EVENT_REGISTRATION = 'SHOW_MODAL_EVENT_REGISTRATION';
export const HUB_TEAM_UPDATED = 'HUB_TEAM_UPDATED';
export const UPDATE_TOURNAMENT_DATA_IN_MY_TEAM = 'UPDATE_TOURNAMENT_DATA_IN_MY_TEAM';
export const ACCEPT_TEAM_REGISTRATION = 'ACCEPT_TEAM_REGISTRATION';
export const UPDATE_TEAM_NAME = 'UPDATE_TEAM_NAME';

// qualifier tournament
export const QUALIFIER_TOURNAMENT_NOTIFICATION_ENDED = 'QUALIFIER_TOURNAMENT_NOTIFICATION_ENDED';
export const QUALIFIER_TOURNAMENT_NOTIFICATION_ENDED_PUBLIC = 'QUALIFIER_TOURNAMENT_NOTIFICATION_ENDED_PUBLIC';
export const QUALIFIER_TOURNAMENT_NOTIFICATION_FINAL_ROUND_ENDED = 'QUALIFIER_TOURNAMENT_NOTIFICATION_FINAL_ROUND_ENDED';
export const QUALIFIER_TOURNAMENT_NOTIFICATION_START_CHECK_IN = 'QUALIFIER_TOURNAMENT_NOTIFICATION_START_CHECK_IN';
export const QUALIFIER_TOURNAMENT_NOTIFICATION_STOP_CHECK_IN = 'QUALIFIER_TOURNAMENT_NOTIFICATION_STOP_CHECK_IN';
export const QUALIFIER_TOURNAMENT_NOTIFICATION_UPDATE_SCORE_BOARD = 'QUALIFIER_TOURNAMENT_NOTIFICATION_UPDATE_SCORE_BOARD';
export const QUALIFIER_TOURNAMENT_CHANGE_VIEW_SELF_REPORT_TAB = 'QUALIFIER_TOURNAMENT_CHANGE_VIEW_SELF_REPORT_TAB';
export const QUALIFIER_TOURNAMENT_SHOW_MATCH_DISPUTE = 'QUALIFIER_TOURNAMENT_SHOW_MATCH_DISPUTE';
export const QUALIFIER_TOURNAMENT_NOTIFICATION_END_BRACKET_MATCH = 'QUALIFIER_TOURNAMENT_NOTIFICATION_END_BRACKET_MATCH';
export const QUALIFIER_TOURNAMENT_SHOW_QUALIFIER_BRACKET = 'QUALIFIER_TOURNAMENT_SHOW_QUALIFIER_BRACKET';
export const QUALIFIER_TOURNAMENT_HIDE_QUALIFIER_BRACKET = 'QUALIFIER_TOURNAMENT_HIDE_QUALIFIER_BRACKET';
export const QUALIFIER_TOURNAMENT_SHOW_QUALIFIER_SIDE = 'QUALIFIER_TOURNAMENT_SHOW_QUALIFIER_SIDE';
export const QUALIFIER_TOURNAMENT_SEND_GDM = 'QUALIFIER_TOURNAMENT_SEND_GDM';

export const QUALIFIER_TOURNAMENT_OPEN_MATCH_CHAT = 'QUALIFIER_TOURNAMENT_OPEN_MATCH_CHAT';
// end qualifier tournament

// notification when tournament live
export const COD_TOURNAMENT_START_BRACKET = 'COD_TOURNAMENT_START_BRACKET';
export const COD_TOURNAMENT_UPDATE_KILL_BRACKET = 'COD_TOURNAMENT_UPDATE_KILL_BRACKET';
export const COD_TOURNAMENT_DONE_MATCH = 'COD_TOURNAMENT_DONE_MATCH';
export const COD_TOURNAMENT_UPDATE_ROUND_ROBIN_SCORE = 'COD_TOURNAMENT_UPDATE_ROUND_ROBIN_SCORE';
export const COD_TOURNAMENT_LAST_ROUND_ROBIN_CREATED = 'COD_TOURNAMENT_LAST_ROUND_ROBIN_CREATED';
export const COD_TOURNAMENT_CHANGE_ROUND_ROBIN_TO_BRACKET = 'COD_TOURNAMENT_CHANGE_ROUND_ROBIN_TO_BRACKET';
export const COD_TOURNAMENT_UPDATE_KILL_SWISS = 'COD_TOURNAMENT_UPDATE_KILL_SWISS';
export const COD_TOURNAMENT_END_ROUND_SWISS = 'COD_TOURNAMENT_END_ROUND_SWISS';
export const COD_TOURNAMENT_CHANGE_ROUND_SWISS_TO_BRACKET = 'COD_TOURNAMENT_CHANGE_ROUND_SWISS_TO_BRACKET';
export const COD_TOURNAMENT_FINAL_MATCH_ENDED = 'COD_TOURNAMENT_FINAL_MATCH_ENDED';
export const COD_TOURNAMENT_UPDATE_WINNER_TOURNAMENT = 'COD_TOURNAMENT_UPDATE_WINNER_TOURNAMENT';
export const COD_TOURNAMENT_START_BR_CUSTOM = 'COD_TOURNAMENT_START_BR_CUSTOM';
export const COD_TOURNAMENT_START_TOURNAMENT = 'COD_TOURNAMENT_START_TOURNAMENT';
export const COD_TOURNAMENT_UPDATE_BEST_OF = 'COD_TOURNAMENT_UPDATE_BEST_OF';
export const COD_TOURNAMENT_UPDATE_PUBLISH_BULLETIN = 'COD_TOURNAMENT_UPDATE_PUBLISH_BULLETIN';
export const COD_TOURNAMENT_UPDATE_REMOVE_BULLETIN = 'COD_TOURNAMENT_UPDATE_REMOVE_BULLETIN';
export const COD_TOURNAMENT_UPDATE_TIMED_RACE_SUBMISSION = 'COD_TOURNAMENT_UPDATE_TIMED_RACE_SUBMISSION';
// end notification when tournament live

// event for bracket control panel
export const BRACKET_CONTROL_SWITCH_SWISS = 'BRACKET_CONTROL_SWITCH_SWISS';

// for flow register
export const QUALIFICATION_SIGNUP_TEAM_CHANGE = 'QUALIFICATION_SIGNUP_TEAM_CHANGE';
export const QUALIFICATION_SIGNUP_REMOVE_PENDING = 'QUALIFICATION_SIGNUP_REMOVE_PENDING';
export const QUALIFICATION_SIGNUP_TEAMMATE_LEAVE_BRACKET = 'QUALIFICATION_SIGNUP_TEAMMATE_LEAVE_BRACKET';
export const QUALIFICATION_SIGNUP_RESET_INVITE_STATUS = 'QUALIFICATION_SIGNUP_RESET_INVITE_STATUS';
export const QUALIFIER_TOURNAMENT_KILL_RACE_START = 'QUALIFIER_TOURNAMENT_KILL_RACE_START';
export const QUALIFIER_SIGNUP_MATCH_TEAMMATE = 'QUALIFIER_SIGNUP_MATCH_TEAMMATE';
export const QUALIFICATION_SIGNUP_NEW_TEAM_BRACKET = 'QUALIFICATION_SIGNUP_NEW_TEAM_BRACKET';
export const QUALIFICATION_SIGNUP_START_REGISTRATION = 'QUALIFICATION_SIGNUP_START_REGISTRATION';
export const QUALIFICATION_SIGNUP_CHANGE_SUB_TAB = 'QUALIFICATION_SIGNUP_CHANGE_SUB_TAB';
export const QUALIFICATION_SIGNUP_CHANGE_TAB = 'QUALIFICATION_SIGNUP_CHANGE_TAB';

export const TOURNAMENT_DISPUTE_ACCEPT = 'TOURNAMENT_DISPUTE_ACCEPT';

export const HUB_DROPS_USER_EARN_POINT = 'HUB_DROPS_USER_EARN_POINT';
export const HUB_DROPS_DRAGING_EVENT = 'HUB_DROPS_DRAGING_EVENT';
export const HUB_DROPS_INIT_CAROUSEL = 'HUB_DROPS_INIT_CAROUSEL';
export const SUBSCRIBE_HUB_DROP = 'SUBSCRIBE_HUB_DROP';
export const UNSUBSCRIBE_HUB_DROP = 'UNSUBSCRIBE_HUB_DROP';
export const SHOW_SUBSCRIBE_HUB_DROP_MODAL = 'SHOW_SUBSCRIBE_HUB_DROP_MODAL';
export const NOTIFICATION_HUB_DROPS_UPDATE_QUEST_STATUS = 'NOTIFICATION_HUB_DROPS_UPDATE_QUEST_STATUS';

// hub
export const HUB_CHANGE_TAB = 'HUB_CHANGE_TAB';
export const HUB_GET_UPCOMING_SCRIMMAGE = 'HUB_GET_UPCOMING_SCRIMMAGE';
export const HUB_UPDATE_TEAM = 'HUB_UPDATE_TEAM';
export const HUB_UPDATE_LIST_TEAM_MANAGEMENT = 'HUB_UPDATE_LIST_TEAM_MANAGEMENT';
export const HUB_UPDATE_TEAM_PLAYER = 'HUB_UPDATE_TEAM_PLAYER';
export const HUB_SHOW_FILTER_UPCOMING_SCRIMMAGE = 'HUB_SHOW_FILTER_UPCOMING_SCRIMMAGE';
export const HUB_HIDE_FILTER_UPCOMING_SCRIMMAGE = 'HUB_HIDE_FILTER_UPCOMING_SCRIMMAGE';
export const HUB_CANCEL_FILTER_UPCOMING_SCRIMMAGE = 'HUB_CANCEL_FILTER_UPCOMING_SCRIMMAGE';
export const HUB_IS_HANDLE_FILTER_UPCOMING_SCRIMMAGE = 'HUB_IS_HANDLE_FILTER_UPCOMING_SCRIMMAGE';
export const HUB_SHOW_FILTER_MANAGE_SCRIMMAGE = 'HUB_SHOW_FILTER_MANAGE_SCRIMMAGE';
export const HUB_HIDE_FILTER_MANAGE_SCRIMMAGE = 'HUB_HIDE_FILTER_MANAGE_SCRIMMAGE';
export const HUB_CANCEL_FILTER_MANAGE_SCRIMMAGE = 'HUB_CANCEL_FILTER_MANAGE_SCRIMMAGE';
export const HUB_IS_HANDLE_FILTER_MANAGE_SCRIMMAGE = 'HUB_IS_HANDLE_FILTER_MANAGE_SCRIMMAGE';
export const HUB_UPDATE_LIST_ANNOUNCEMENT = 'HUB_UPDATE_LIST_ANNOUNCEMENT';
export const HUB_UPDATE_TEAM_DATA = 'HUB_UPDATE_TEAM_DATA';
export const HUB_UPDATE_TEAM_MEMBER_DATA = 'HUB_UPDATE_TEAM_MEMBER_DATA';
export const HUB_VERIFY_PHONE_NUMBER_SUCCESS = 'HUB_VERIFY_PHONE_NUMBER_SUCCESS';
export const HUB_ACCEPT_OWNER_REQUEST_SUCCESS = 'HUB_ACCEPT_OWNER_REQUEST_SUCCESS';

// search
export const SEARCH_UPDATE_QUERY = 'SEARCH_UPDATE_QUERY';
export const SEARCH_HANDLE_SEARCH = 'SEARCH_HANDLE_SEARCH';
export const TOGGLE_SEARCH_BAR = 'TOGGLE_SEARCH_BAR';

// admin creator
export const ADVANCED_CREATE_TOURNAMENT = 'ADVANCED_CREATE_TOURNAMENT';
export const ADMIN_CHANGE_EVENT_TITLE_CREATING = 'ADMIN_CHANGE_EVENT_TITLE_CREATING';
export const ADMIN_SHOW_CREATE_DETAILS = 'ADMIN_SHOW_CREATE_DETAILS';
export const SHOW_ADMIN_INSPECT = 'SHOW_ADMIN_INSPECT';
export const CLOSE_ADMIN_INSPECT = 'CLOSE_ADMIN_INSPECT';
export const SHOW_MODAL_CHOOSE_ADVANCED_TEAM_ROUND_ROBIN = 'SHOW_MODAL_CHOOSE_ADVANCED_TEAM_ROUND_ROBIN';
export const ADMIN_CONTROL_SWITCH_SWISS = 'ADMIN_CONTROL_SWITCH_SWISS';
export const ADMIN_GET_LIST_TEAM = 'ADMIN_GET_LIST_TEAM';
export const ADMIN_GET_TEAM_DETAIL = 'ADMIN_GET_TEAM_DETAIL';
export const ADMIN_CREATOR_DONE_MATCH = 'ADMIN_CREATOR_DONE_MATCH';
export const ADMIN_CREATOR_UPDATE_KILL_BRACKET = 'ADMIN_CREATOR_UPDATE_KILL_BRACKET';
export const ADMIN_CREATOR_UPDATE_BEST_OF_BRACKET = 'ADMIN_CREATOR_UPDATE_BEST_OF_BRACKET';
export const ADMIN_CREATOR_UPDATE_BEST_OF_SCORE = 'ADMIN_CREATOR_UPDATE_BEST_OF_SCORE';
export const ADMIN_CREATOR_UPDATE_STATUS_MATCH = 'ADMIN_CREATOR_UPDATE_STATUS_MATCH';
export const ADMIN_CREATOR_NOTICE_MATCH_DRAW = 'ADMIN_CREATOR_NOTICE_MATCH_DRAW';
export const ADMIN_CREATOR_FINAL_MATCH_ENDED = 'ADMIN_CREATOR_FINAL_MATCH_ENDED';
export const ADMIN_CREATOR_UPDATE_ROUND_ROBIN_SCORE = 'ADMIN_CREATOR_UPDATE_ROUND_ROBIN_SCORE';
export const ADMIN_CREATOR_UPDATE_ROUND_ROBIN_BEST_OF = 'ADMIN_CREATOR_UPDATE_ROUND_ROBIN_BEST_OF';
export const ADMIN_CREATOR_LAST_ROUND_ROBIN_CREATED = 'ADMIN_CREATOR_LAST_ROUND_ROBIN_CREATED';
export const ADMIN_CREATOR_CHANGE_ROUND_ROBIN_TO_BRACKET = 'ADMIN_CREATOR_CHANGE_ROUND_ROBIN_TO_BRACKET';
export const ADMIN_CREATOR_UPDATE_KILL_SWISS = 'ADMIN_CREATOR_UPDATE_KILL_SWISS';
export const ADMIN_CREATOR_END_ROUND_SWISS = 'ADMIN_CREATOR_END_ROUND_SWISS';
export const ADMIN_CREATOR_CHANGE_ROUND_SWISS_TO_BRACKET = 'ADMIN_CREATOR_CHANGE_ROUND_SWISS_TO_BRACKET';
export const ADMIN_CREATOR_REFRESH_KILL_RACE_DATA = 'ADMIN_CREATOR_REFRESH_KILL_RACE_DATA';
export const ADMIN_CREATOR_RESTART_BRACKET = 'ADMIN_CREATOR_RESTART_BRACKET';
